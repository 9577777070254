import React, { useState } from "react";

import { Box, Menu } from "@mui/material";

const CMenu = ({ children, anchorOrigin, transformOrigin, trigger, isHover = false, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      {trigger ? React.cloneElement(trigger, { onClick: handleClick }) : null}

      <Menu
        className="mui-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...props}
      >
        {children}
      </Menu>
    </Box>
  );
};

export default CMenu;
