import { Navigate, useRoutes } from "react-router-dom";

import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import PublicRoutes from "./PublicRoutes";
import WelcomeRoutes from "./WelcomeRoutes";

import Home from "../pages/Home";
import Video from "../pages/Video";

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/video",
      element: <Video />,
    },
    LoginRoutes,
    MainRoutes,
    PublicRoutes,
    WelcomeRoutes,
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);
}

// cid = candidate id
// jid = job id
// id = candidate id
// iid = interview id
