import { createSlice } from "@reduxjs/toolkit";

import { InvitedUserInfo, User } from "@/types/state";

interface UserInitialState {
  loading: boolean;
  isLoggedIn: boolean;
  user: User | null;
  inviteUser: InvitedUserInfo | null;
}

const initialState: UserInitialState = {
  loading: true,
  isLoggedIn: false,
  user: null,
  inviteUser: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userSuccess(state, action) {
      const { user } = action.payload;
      state.user = user;
      state.isLoggedIn = true;
      state.loading = false;
    },
    logoutSuccess(state, action) {
      state.user = null;
      state.inviteUser = null;
      state.loading = false;
    },
    inviteUserSuccess(state, action) {
      const { inviteUser } = action.payload;
      state.inviteUser = inviteUser;
      state.loading = false;
    },
    joinWorkspaceSuccess(state, action) {
      state.inviteUser = null;
      state.loading = false;
    },
    addNewUserRoleSuccess(state, action) {
      const { role } = action.payload;
      if (state.user) {
        state.user = { ...state.user, roles: [...state.user.roles, role] };
      }
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const { userSuccess, logoutSuccess, loadingSuccess, inviteUserSuccess, joinWorkspaceSuccess, addNewUserRoleSuccess } = userSlice.actions;

export default userSlice.reducer;
