import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { Logo } from "../../../utils/images";

const DrawerHeaderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-start" : "center",
  padding: theme.spacing(6),
  gap: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,

  "& img": {
    width: "40px",
    height: "40px",
  },

  "& h6": {
    color: theme.palette.text.primary,
  },

  "& p": {
    color: theme.palette.text.secondary,
  },
}));

const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <img src={Logo} alt="logo" />

      <Box sx={{ display: "flex", gap: theme.spacing(1), flexDirection: "column" }}>
        <Typography variant="h6">Auma</Typography>
        <Typography variant="body2">HR Management</Typography>
      </Box>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
