import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  calUser: null,
  accessToken: "",
  calendars: [],
  destination: null,
};

const calComSlice = createSlice({
  name: "calCom",
  initialState,
  reducers: {
    calComUserSuccess(state, action) {
      const { user } = action.payload;
      if (user && user.data) {
        state.calUser = user.data;
        state.accessToken = user.data.access_token;
      }
      state.loading = false;
    },
    setConnectedCalendarsSuccess(state, action) {
      const { calendars, destination } = action.payload;
      state.calendars = calendars;
      state.destination = destination;
      state.loading = false;
    },
    changeDestinationSuccess(state, action) {
      const { destination } = action.payload;
      state.destination = destination;
      state.loading = false;
    },
    loadingSuccess(state, action) {
      const { loading } = action.payload;
      state.loading = loading;
    },
  },
});

export const { loadingSuccess, calComUserSuccess, setConnectedCalendarsSuccess, changeDestinationSuccess } = calComSlice.actions;

export default calComSlice.reducer;
