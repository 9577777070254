import React from "react";

import { Outlet } from "react-router-dom";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Logo, WelcomeBgVector } from "../../utils/images";

const WelcomeLayout = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {!matchDownSM ? (
        <>
          <Box sx={{ position: "relative", width: "100%", height: "100%", minHeight: "100vh", padding: "24px 44px" }}>
            <img src={Logo} alt="" style={{ position: "absolute", top: "24px", left: "44px", width: "40px", height: "40px" }} />
            <img src={WelcomeBgVector} alt="" style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }} />
            <Box sx={{ zIndex: 2, position: "relative" }}>
              <Outlet />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            background: "#F6F8FA",
            padding: "24px 44px",
            display: "flex",
          }}
        >
          <Box sx={{ zIndex: 2, position: "relative", flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
            <Outlet />
          </Box>
          <Typography
            variant="paragraphXS"
            style={{ textAlign: "center", color: "#CDD0D5", position: "absolute", bottom: "24px", left: "50%", transform: "translateX(-50%)" }}
          >
            © 2024 Auma
          </Typography>
        </Box>
      )}
    </>
  );
};

export default WelcomeLayout;
