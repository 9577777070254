import axios from "axios";

import { API_URL } from "./environment";
import { cookieStorage } from "./cookie";
import { openErrorSnackbar } from "../helper/snackbar";
import { SnackBarVariant } from "../enums/snackVariant";

const axiosServices = axios.create({ baseURL: API_URL + "/api/v1" });
// const axiosServices = axios.create({ baseURL: "https://beprodyuytaqiota.theauma.com" + "/api/v1" });

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.status === 407) {
      cookieStorage.clear();
      window.location.href = "/login";
    } else if (error.status === 406) {
      openErrorSnackbar({ message: "You are unauthorized to perform this action.", variant: SnackBarVariant.Error });
    } else {
      openErrorSnackbar({ message: error.response.data.message || error.response.data.msg, variant: SnackBarVariant.Error });
      return Promise.reject(error);
    }
  }
);

export default axiosServices;

// axiosServices.interceptors.request.use(
//   (config) => {
//     // Dispatch loading state to true when a request starts
//     store.dispatch(loadingSuccess({ loading: true }));
//     return config;
//   },
//   (error) => {
//     // Handle request error
//     store.dispatch(loadingSuccess({ loading: false }));
//     return Promise.reject(error);
//   }
// );
