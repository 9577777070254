const Video = () => {
  return (
    <div>
      {/* <iframe
        src={"https://app.cal.com/video/2gJBMJB5dF7M3PHWxE7UV4"}
        style={{ width: "100%", height: "100vh" }}
        frameBorder="0"
        title="cal.com"
      ></iframe> */}
    </div>
  );
};

export default Video;
