import React, { useEffect, useState } from "react";

import { Box, Dialog, DialogActions, DialogContent, IconButton, MenuItem, Typography, LinearProgress, Menu as MuiMenu, Popover } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { linearProgressClasses } from "@mui/material/LinearProgress";

import { RiArrowRightSLine, RiLogoutBoxLine, RiInformationFill, RiCoinLine } from "react-icons/ri";

import Menu from "../../../components/Menu";
import Button from "../../../components/ui/Button";
import StatusModal from "../../../components/StatusModal";

import { dispatch, useSelector } from "../../../store";
import { logoutSuccess } from "../../../store/slices/user";

import { cookieStorage } from "../../../utils/cookie";
import { CoinLine, DefaultProfileIcon } from "../../../utils/images";

const DrawerFooterStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-start" : "center",
  padding: theme.spacing(6),
  gap: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,

  "& img": {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },

  "& h6": {
    color: theme.palette.text.primary,
  },

  "& p": {
    width: "135px",
    color: theme.palette.text.secondary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 32,
  border: "1px solid #E2E4E9",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#DBFFBF",
  },
}));

const DrawerFooter = ({ open }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);

  const [isLogoutDialogOpen, setLogoutDialog] = useState(false);
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    photo: "",
  });

  useEffect(() => {
    if (user) {
      setUserDetail({
        name: user.fullName,
        email: user.email,
        photo: user.profileImage,
      });
    }
  }, [user]);

  const logout = () => {
    cookieStorage.removeItem("jwt_uuid");
    cookieStorage.removeItem("uuid");
    cookieStorage.clear();
    dispatch(logoutSuccess());
    navigate("/login");
  };

  const handleProfilePhotoError = (event) => {
    event.target.onerror = null;
    event.target.src = DefaultProfileIcon;
  };

  return (
    <>
      {/* <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "18px 16px 16px 18px",
            left: "280px !important",
            padding: "0 !important",
            boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)",
            width: "325px",
          },
        }}
        trigger={
          <Box
            sx={{
              borderRadius: "16px",
              border: "1px solid #E2E4E9",
              background: "#FFF",
              padding: "14px 10px",
              margin: "16px 20px",
              boxShadow: "0px 16px 32px -12px rgba(88, 92, 95, 0.10)",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", zIndex: 9, position: "relative" }}>
              <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <RiCoinLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
                  <Typography variant="labelSM" color="#525866">
                    8 / 150 Credits
                  </Typography>
                </Box>
                <BorderLinearProgress variant="determinate" value={12} />
              </Box>
              <Button variant="auma" state="Stroke" size="x-small" title="Upgrade" onClick={() => navigate(`/company/${user?._id}/pricing`)} />
            </Box>
          </Box>
        }
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <Box
          sx={{
            borderBottom: "1px solid #E2E4E9",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#F6F8FA",
          }}
        >
          <Typography variant="labelSM" color="#525866">
            Remaining Credits
          </Typography>
          <Button img={CoinLine} variant="neutral" state="Stroke" size="x-small" title="142" sx={{ borderRadius: "10px", padding: "5px 14px" }} />
        </Box>
        <Box sx={{ padding: "18px", display: "flex", alignItems: "center", gap: "12px", width: "100%", zIndex: 9, position: "relative" }}>
          <Button variant="neutral" state="Stroke" size="x-small" title="Buy more credits" fullWidth />
          <Button
            variant="auma"
            state="Stroke"
            size="x-small"
            title="Manage plan"
            fullWidth
            onClick={() => navigate(`/company/${user?._id}/pricing`)}
          />
        </Box>
      </Menu> */}

      <DrawerFooterStyled theme={theme} open={open}>
        <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(3) }}>
          <img src={userDetail.photo} alt="user" onError={handleProfilePhotoError} />
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{userDetail.name || "N/a"}</Typography>
            <Typography title={userDetail.email || "N/a"} variant="body2">
              {userDetail.email || "N/a"}
            </Typography>
          </Box>
        </Box>
        <Menu
          trigger={
            <IconButton sx={{ borderRadius: "8px" }}>
              <RiArrowRightSLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
            </IconButton>
          }
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        >
          {/* <MenuItem>
            Settings <RiSettings3Line size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem> */}
          <MenuItem onClick={() => setLogoutDialog(true)}>
            Logout <RiLogoutBoxLine size={theme.icon.size.lg} color={theme.palette.text.secondary} />
          </MenuItem>
        </Menu>
      </DrawerFooterStyled>

      <Dialog fullWidth maxWidth="xs" open={isLogoutDialogOpen} className="confirm-dialog">
        <DialogContent>
          <Box className="status-pill">
            <StatusModal variant="Red" icon={<RiInformationFill size={theme.icon.size.lg} />} />
          </Box>
          <Typography variant="body1">Are You Sure You Want to Log Out?</Typography>
          <Typography variant="subtitle1">You are about to log out of your account.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialog(false)} fullWidth title="Cancel" variant="neutral" state="Stroke" />
          <Button onClick={logout} fullWidth title="Log Out" variant="primary" state="Filled" />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DrawerFooter;
