import axios from "axios";

import { cookieStorage } from "../utils/cookie";
import axiosServices from "../utils/axios";
import { CAL_API_URL, CAL_OAUTH_CLIENT_ID } from "../utils/environment";

import { calComUserSuccess, loadingSuccess, setConnectedCalendarsSuccess } from "../store/slices/calcom";
import { dispatch } from "../store";

export const getCalUserService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/cal/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(calComUserSuccess({ user: response.data }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const checkAccessTokenService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axios.get(`${CAL_API_URL}/provider/${CAL_OAUTH_CLIENT_ID}/access-token`, {
        headers: {
          Authorization: `Bearer ${getState().calCom.accessToken}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getConnectedCalendarsService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axios.get(`${CAL_API_URL}/calendars`, {
        headers: {
          Authorization: `Bearer ${getState().calCom.accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response && response.data) {
        const { data } = response.data;
        dispatch(setConnectedCalendarsSuccess({ calendars: data.connectedCalendars || [], destination: data.destinationCalendar }));
      }
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const inviteCalUserService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post("/company/create-cal-user", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const createCalEventService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post("/jobs/create-cal-event", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const requestRescheduleBookingService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post("/cal/request-reschedule", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const addBookingGuestsService = (body) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.post("/cal/add-guest", body, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};

export const getBookingInterviewService = (id) => {
  return async () => {
    try {
      dispatch(loadingSuccess({ loading: true }));
      const response = await axiosServices.get(`/cal/get-event-details/${id}`, {
        headers: {
          Authorization: `Bearer ${cookieStorage.getItem("jwt_uuid")}`,
          "x-custom-access-id": `${cookieStorage.getItem("uuid")}`,
        },
      });
      dispatch(loadingSuccess({ loading: false }));
      return response.data;
    } catch (error) {
      dispatch(loadingSuccess({ loading: false }));
    }
  };
};
